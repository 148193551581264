import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import Select from 'react-select';
import Swal from 'sweetalert2';
import {newAPI} from '../../services/baseAPI';
import useUserStore from '../../stores/Users';
import LoadingView from '../../components/Loading';

const Notification = () => {
  const ADMIN = 1;
  const AGRONOMIST = 6;
  const token = useUserStore(state => state.token);
  const users = useUserStore(state => state.user);
  const [loading, setLoading] = useState(false);
  const [farms, setFarms] = useState([]);
  const [notificationTypes, setNotificationTypes] = useState([
    {label: 'Push Notification', value: 'push_notif'},
  ]);
  const {register, handleSubmit, setValue, watch, errors} = useForm({
    defaultValues: {
      title: '',
      textBody: '',
      note: '',
      type: null,
      farm: null,
    },
  });

  const getFarms = async () => {
    try {
      setLoading(true);
      const response = await newAPI.get(`/v3/farms`, {
        headers: {Authorization: `Bearer ${token}`},
      });

      const lastDataDevice = response.data.data.last_data_device;
      const dataFarm = lastDataDevice
        .filter(item => item.jenis_data === 'config')
        .map(item => ({
          value: item.code_farm,
          label: item.name,
        }));

      const allFarm =
        users.status === ADMIN
          ? [{value: 'all', label: 'Semua Kebun'}, ...dataFarm]
          : dataFarm;

      setFarms(allFarm);
    } catch (error) {
      console.error(`Error fetching farms: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const sendDataNotif = async data => {
    setLoading(true);
    const body = {
      title: data.title,
      textBody: data.textBody,
      note: data.note,
      codeFarm: data.farm,
      type: data.type,
    };

    try {
      const response = await newAPI.post(
        `/global/v1/notifications/push-notification`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        }
      );
      Swal.fire('Success!', 'Notifikasi berhasil dikirim', 'success');
    } catch (error) {
      console.error(`Error sending notification: ${error}`);
      Swal.fire('Error!', 'Gagal mengirim notifikasi', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    register('farm');
    register('type');
    register('title');
    register('textBody');
    register('note');
    getFarms();
  }, []);

  const headerView = () => (
    <>
      <div className="page-header d-print-none">
        <div className="row align-items-center">
          <div className="col">
            <div className="page-pretitle">Menu</div>
            <h2 className="page-title">Notifikasi</h2>
          </div>
        </div>
      </div>
    </>
  );

  const pushNotificationView = () => {
    const watchType = watch('type');
    const watchFarm = watch('farm');

    return (
      <div className="row">
        <div className="col md">
          <div className="border-start ps-4 pt-3 pb-3 border-5 ">
            <b>
              <h4 style={{color: 'red'}}>Perhatian !</h4>
            </b>
            <p>Inputan yang anda kirim, akan langsung diterima oleh user</p>
            <p>Periksa terlebih dahulu sebelum mengirim notifikasi</p>
          </div>
        </div>

        <div className="col md mb-2">
          <form onSubmit={handleSubmit(sendDataNotif)}>
            <div className="mb-3">
              <label htmlFor="title" className="fw-bold mb-1">
                Judul
              </label>
              <input
                ref={register({required: true})}
                name="title"
                type="text"
                className="form-control"
                placeholder="Judul Notifikasi"
              />
              {errors.title && (
                <p className="text-danger"> Judul wajib diisi</p>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="textBody" className="fw-bold mb-1">
                Masukan konten notifikasi
              </label>
              <textarea
                ref={register({
                  required: true,
                })}
                name="textBody"
                type="text"
                className="form-control"
                placeholder="Konten notifikasi"
                style={{height: '100px'}}
              />
              {errors.textBody && (
                <p className="text-danger">Konten notifikasi wajib diisi</p>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="note" className="fw-bold mb-1">
                Masukan note
              </label>
              <textarea
                ref={register}
                name="note"
                type="text"
                className="form-control"
                placeholder="Note notifikasi"
                style={{height: '100px'}}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="farm" className="fw-bold mb-1">
                Kebun
              </label>
              <Select
                options={farms}
                placeholder="Pilih kebun"
                onChange={e => setValue('farm', e.value)}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="type" className="fw-bold mb-1">
                Tipe Notifikasi
              </label>
              <Select
                options={notificationTypes}
                placeholder="Pilih tipe"
                onChange={e => setValue('type', e.value)}
              />
            </div>

            <div className="d-grid">
              <button
                disabled={!watchType || !watchFarm}
                type="submit"
                className="btn btn-success">
                Kirim Notifikasi
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const handleView = () => {
    switch (users.status) {
      case ADMIN:
      case AGRONOMIST:
        return pushNotificationView();
      default:
        return null;
    }
  };

  return (
    <div className="container-xl">
      {headerView()}
      <div className="row row-deck row-cards">
        {loading ? <LoadingView /> : handleView()}
      </div>
    </div>
  );
};

export default Notification;
